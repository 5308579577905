// @flow
import { pick, mapValues } from 'lodash';

import type {
  CartStoreState,
  BackendExpectedCart,
  BackendExpectedCartItem,
} from '@/types/stores';
import type { CartItem } from '@/types/models';
import type { PaymentIntent } from '@/types/stripe';

import { getDerivedState } from './derivedState';
import API from '@/utils/API';
import invariant from '@/helpers/invariant';

const cleanupItem = (item: CartItem<*>): BackendExpectedCartItem => ({
  quantity: item.quantity,
  userPrice: item.userPrice,
  entity: {
    _id: item.entity._id,
    _cls: item.entity._cls,
  },
  options: item.options,
});

export const getBackendCart = (
  cartState: CartStoreState
): BackendExpectedCart => {
  const {
    userWantsABill,
    cartItems,
    shippingAddress,
    discountCode,
    isGift,
    giftInfo,
  } = cartState;
  const { billingAddress, requiresShipping } = getDerivedState(cartState);

  return {
    ...pick(cartState, ['donation']),
    giftInfo: isGift ? giftInfo : null,
    cartItems: mapValues(cartItems, cleanupItem),
    shippingAddress,
    billingAddress,
    requiresBilling: requiresShipping || userWantsABill,
    discountCode,
  };
};

export const notifyCartEmpty = (instanceId: string): Promise<void> =>
  API.post(`${instanceId}/cart/notifyCartEmpty`);

export const validateCart = (cartState: CartStoreState): Promise<*> => {
  const { instanceId } = cartState;
  const cart = getBackendCart(cartState);

  return API.post(`${instanceId}/cart/validateCart`, cart);
};

export const createPaymentIntent = (
  cartState: CartStoreState
): Promise<PaymentIntent> => {
  const { instanceId } = cartState;
  const cart = getBackendCart(cartState);
  const { total } = getDerivedState(cartState);

  return API.post(`${instanceId}/users/createIntent`, {
    price: total,
    save: false,
    cart,
  });
};

export const updatePaymentIntent = (
  cartState: CartStoreState
): Promise<PaymentIntent> => {
  const { instanceId, paymentIntent } = cartState;
  invariant(
    paymentIntent,
    'Payment intent should exist when trying to update it'
  );

  return API.post(`${instanceId}/users/triggerUpdateIntent`, {
    intentType: paymentIntent.object,
    intentId: paymentIntent.id,
  });
};

// @flow

import type { User } from '@/types/models';

export const getFullName = (user: User): string => {
  if (!user) return '';

  const { firstName, lastName, nickname, name } = user;
  if (firstName || lastName) {
    return `${firstName} ${lastName}`;
  }

  if (nickname) return nickname;
  if (name) return name;
  return '';
};

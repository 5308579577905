// @flow
import { useContext } from 'react';

import type { Styles } from '@/types/site';

import { SiteContext } from '@/globals/contexts';

export default function useStyle(): Styles {
  const siteContext = useContext(SiteContext);

  if (!siteContext) throw new Error('Missing site context');

  return siteContext.siteConfig.styles;
}

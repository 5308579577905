// @flow
import { useContext } from 'react';

import type { LinksContext as LinksContextType } from '@/types/site';

import { LinksContext } from '@/pageComponents/contexts';

export default function useLinks(): LinksContextType {
  const linkContext = useContext(LinksContext);

  if (!linkContext) throw new Error('Missing links context');

  return linkContext;
}

// @flow
import { useContext } from 'react';

import type { SiteContext as SiteContextType } from '@/types/site';

import { SiteContext } from '@/globals/contexts';

export default function useSite(): SiteContextType {
  const siteContext = useContext(SiteContext);

  if (!siteContext) throw new Error('Missing site context');

  return siteContext;
}

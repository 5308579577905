// @flow
import { some } from 'lodash';

import type {
  CartStoreState,
  CartStoreDerivedState,
  CartDerivedErrors,
} from '@/types/stores';
import type { CartItems, Address } from '@/types/models';

import { getErrors, getEmptyErrors } from './errors';

export const hasError = (errors: CartDerivedErrors): boolean => {
  return Object.keys(errors).some((errorType) => {
    if (errorType === 'cartItems') {
      const itemErrors = errors.cartItems;
      return Object.keys(itemErrors).some(
        (itemId) => itemErrors[itemId].length > 0
      );
    }

    return errors[errorType].length > 0;
  });
};

export const shippingRequired = (items: CartItems): boolean =>
  Object.keys(items).some((itemId) => !!items[itemId].entity.requiresShipping);

export const getBillingAddress = (cartState: CartStoreState): ?Address => {
  const {
    cartItems,
    billingAddressSameAsShippingAddress,
    shippingAddress,
    billingAddress,
    userWantsABill,
  } = cartState;
  const requiresShipping = shippingRequired(cartItems);

  if (requiresShipping) {
    if (billingAddressSameAsShippingAddress) return shippingAddress;
    return billingAddress;
  }

  if (userWantsABill) return billingAddress;
  return null;
};

export const isGiftable = (cartItems: CartItems): boolean =>
  Object.keys(cartItems).every((itemId) => cartItems[itemId].entity.giftable);

export const someItemsAreGiftable = (cartItems: CartItems): boolean =>
  Object.keys(cartItems).some((itemId) => cartItems[itemId].entity.giftable);

export const getDerivedState = (
  cartState: CartStoreState
): CartStoreDerivedState => {
  const { cartItems, hasValidated } = cartState;

  const requiresShipping = shippingRequired(cartItems);
  const billingAddress = getBillingAddress(cartState);
  const errors = getErrors(cartState);

  return {
    requiresShipping,
    billingAddress,
    isGiftable: isGiftable(cartItems),
    someItemsAreGiftable: someItemsAreGiftable(cartItems),
    total: getTotal(cartState),
    errors,
    hasError: hasError(errors),
    hasUiErrors: hasValidated ? hasError(errors) : false,
    uiErrors: hasValidated ? errors : getEmptyErrors(cartState),
  };
};

export const canDonate = (cartState: CartStoreState): boolean =>
  !some(cartState.cartItems, 'entity.disableDonation');

export const getTotal = (cartState: CartStoreState): number => {
  const { donation, cartItems, shippingCost, totalDiscount } = cartState;

  const sumReducer = (previousValue, currentValue) =>
    previousValue + currentValue;

  const cartTotal =
    donation +
    (shippingCost || 0) -
    (totalDiscount || 0) +
    Object.keys(cartItems)
      .map((itemId) => {
        const item = cartItems[itemId];
        if (item.userPrice) return item.userPrice * item.quantity;
        return item.entity.price * item.quantity;
      })
      .reduce(sumReducer, 0);

  return cartTotal;
};

import NLink from 'next/link';
import Router from 'next/router';
import React from 'react';

import { serializeQueryString } from '@/utils/misc';
import { useUrl, useLinks } from '@/hooks';
import {
  interpolateContent,
  type InterpolateOptions,
} from '@/helpers/interpolation';

export function goToPage(pageId, { query, queryString, openInANewTab } = {}) {
  let pageIdEncoded = pageId && encodeURIComponent(pageId);
  let qs = queryString || (query && serializeQueryString(query, false));

  Router.push((pageId === 'home' ? '/' : pageIdEncoded) + (qs ? '?' + qs : ''));
  // Must scroll to top, browser won't do it... TODO better
  window.scroll({ top: 0 });
}

export function followLink(link) {
  // To support links from LinkContext
  if (link.isPageId) return goToPage(link.url, { query: link.query });

  if (link.pageId)
    return goToPage(link.pageId, { queryString: link.queryString });

  if (link.url) {
    const url = new URL(
      `${link.url}${link.queryString ? '?' + link.queryString : ''}`
    );
    if (link.isNewWindow) return window.open(url.href, '_blank').focus();
    return (window.location.href = url.href);
  }
}

export function formatQueryParams(
  queryParams: string | Object,
  interpolateOptions: InterpolateOptions
) {
  const { fullUrl } = interpolateOptions;

  switch (typeof queryParams) {
    case 'string':
      return queryParams
        .split('&')
        .map((chunk) => {
          const [key, value] = chunk.split('=');
          return `${encodeURIComponent(key)}=${encodeURIComponent(
            interpolateContent(value, { fullUrl })
          )}`;
        })
        .join('&');
    case 'object':
      const builtQueryParams = Object.keys(queryParams)
        .map((key) => {
          const value = queryParams[key];
          return `${encodeURIComponent(key)}=${encodeURIComponent(
            interpolateContent(value, { fullUrl })
          )}`;
        })
        .join('&');
      return builtQueryParams;
    default:
      return queryParams;
  }
}

/**
 *
 * @param props { pageId[, route] [, query] } with query = {param1: value1, ... }
 * @returns {*}
 * @constructor
 */
export default function PageLink(props) {
  const { fullUrl } = useUrl();

  const links = useLinks();
  const { dynamicPages: dynamicLinks } = links;
  const { query, queryString, children, hash, link } = props;
  const route =
    props.route || (!!link && !link.isPageId ? link.url : undefined);
  const pageId =
    props.pageId || (!!link && link.isPageId ? link.url : undefined);
  const external = props.external || (!!link && !link.internal);

  const qs = formatQueryParams(
    queryString || { ...query, ...(link?.query || {}) },
    { fullUrl }
  );

  const formattedRoute = `${route}${!!qs ? '?' + qs : ''}${
    hash ? `#${hash}` : ''
  }`;

  if (children.length > 1)
    console.error('PageLink requires a unique child', children);

  if (external) return <NLink href={formattedRoute}>{children}</NLink>;

  if (pageId) {
    if (!dynamicLinks[pageId]?.path) return children;

    const path = `${dynamicLinks[pageId].path}${!!qs ? '?' + qs : ''}${
      hash ? `#${hash}` : ''
    }`;
    return <NLink href={path}>{children}</NLink>;
  }

  if (route) {
    return (
      <NLink
        as={formattedRoute} // needed ?
        href={formattedRoute}
      >
        {children}
      </NLink>
    );
  }

  return <span>{children}</span>;
}

PageLink.defaultProps = {
  external: false,
};

import { useContext } from 'react';

import { SiteContext } from '@/globals/contexts';

export default function useRssConfiguration() {
  const context = useContext(SiteContext);

  if (!context) throw new Error('Missing site context');

  return context.siteConfig?.rssConfiguration;
}

// @flow
import { useContext } from 'react';

import type { InstanceName, FeatureFlags } from '@/types/models';

import { SiteContext } from '@/globals/contexts';
import { getFeatureFlags } from '@/helpers/models/instance';

export default function useInstance(): [InstanceName, FeatureFlags] {
  const siteContext = useContext(SiteContext);

  if (!siteContext) throw new Error('Missing site context');

  const featureFlags = getFeatureFlags(siteContext.site);

  return [siteContext.instanceName, featureFlags];
}

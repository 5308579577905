import _ from 'lodash';
import Reflux from 'reflux';

import ApiClient from '@/utils/ApiClient';

/* Component lifecycle (http://busypeoples.github.io/post/react-component-lifecycle/)

INIT
constructor(p)          can set this.state
getDerivedStateFromProps(p, s)       static, return new state
render()
componentDidMount()                  can call setState, DOM ready


UPDATE
getDerivedStateFromProps(p, s)       static, return new state
shouldComponentUpdate(next p, s)     return true/false, do NOT change state
render()
getSnapshotBeforeUpdate(prev p, s)   access DOM before re-render. Return value passed to componentDidUpdate
componentDidUpdate(prev p, s, snap)  can call setState, but beware of loops !


do NOT use :
componentWillMount()
componentWillReceiveProps()
componentWillUpdate()
*/

class ComponentBase extends Reflux.Component {
  /**
   * if not null, calls onScrollLoad when scroll reaches bottom
   * @type {function}
   */
  state = {};

  componentDidMount() {
    if (this.onScrollLoad) {
      let scrollDiv = document.getElementById(
        'componentBasePageScrollContainer'
      );
      this.listenToEvent('scroll', this.onScrollLoad.bind(this), scrollDiv);
    }

    this.didMount && this.didMount();
  }

  /**
   * this.props / this.state contains NEW props / state
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.didUpdate && this.didUpdate(prevProps, prevState, snapshot);

    // Fire observers
    // Change: now in componentWillReceiveProps, was in componentWillUpdate
    prevProps &&
      this._propObservers &&
      this._propObservers.forEach((observer) => {
        if (observer.prop) {
          let oldVal = _.get(prevProps, observer.prop);
          let newVal = _.get(this.props, observer.prop);
          if (!_.isEqual(oldVal, newVal)) observer.cb(newVal, oldVal);
        } else if (observer.props) {
          let oldVals = _.pick(prevProps, observer.props);
          let newVals = _.pick(this.props, observer.props);
          if (!_.isEqual(oldVals, newVals)) observer.cb(newVals, oldVals);
        }
      });
  }

  /**
   * Wait for value / value change of a property.
   * Fired once immediately on function call (if prop not null), then on componentDidUpdate.
   * Can call setState in it (if called in a function where it's allowed).
   *
   * @param propPath      property name or path, eg. "bidule" or "params.truc"
   * @param callback      function(new value, old value) - note: this.props contains new props
   * @param fireIfNull    if true, will fire callback immediately even if prop is null/undefined. Otherwise will wait for prop value to change.
   * @param currentProps  props to use on first call, otherwise will use this.props. Usefull if called in constructor.
   */
  observeProp(propPath, callback, fireIfNull = false, currentProps = null) {
    // Call it now if we have the value
    let val = _.get(this.props, propPath);
    if (!_.isUndefined(val) || fireIfNull) {
      callback(val, null);
    }

    this._propObservers = this._propObservers || [];
    this._propObservers.push({ prop: propPath, cb: callback });
  }

  /**
   * Wait for value / value change of several properties.
   * Fired once immediately on function call, then on componentDidUpdate if any property changes.
   * Can call setState in it (if called in a function where it's allowed).
   *
   * @param propPaths     property paths, eg. ["bidule", "params.truc"]
   * @param callback      function(new values {prop: value...}, old values) - note: this.props contains new props
   * @param currentProps  props to use on first call, otherwise will use this.props. Usefull if called in constructor.
   */
  observeProps(propPaths, callback, currentProps = null, callNow = true) {
    if (callNow) {
      let vals = _.pick(currentProps || this.props, propPaths);
      callback(vals, {});
    }

    this._propObservers = this._propObservers || [];
    this._propObservers.push({ props: propPaths, cb: callback });
  }

  _addUnsub(unsubFunc) {
    this._unsubscribeFunctions = this._unsubscribeFunctions || [];
    this._unsubscribeFunctions.push(unsubFunc);
    return unsubFunc;
  }

  _fromStateOrCallback(stateOrCallback, defaultState) {
    if (!stateOrCallback && defaultState) {
      return (value) => this.setState({ [defaultState]: value });
    }

    if (stateOrCallback && typeof stateOrCallback === 'string') {
      return (value) => this.setState({ [stateOrCallback]: value });
    }

    return stateOrCallback;
  }

  _createLoadCallbacks(
    stateOrCallback,
    onError,
    onStartLoading,
    logStr = 'loaded'
  ) {
    let onSuccess;

    if (typeof stateOrCallback === 'string') {
      let useLoadingState = !onStartLoading;
      // load data into state variable
      onSuccess = (data) => {
        // console.debug(
        //   this.constructor.name + '>',
        //   logStr,
        //   stateOrCallback,
        //   '=',
        //   data
        // );
        this.setState({ [stateOrCallback]: data });
        if (useLoadingState)
          this.setState({
            [stateOrCallback + '_loading']: false,
            loading: false,
          });
      };

      if (useLoadingState)
        onStartLoading = () => {
          console.debug(
            this.constructor.name + '> load',
            stateOrCallback,
            '...'
          );
          this.setState({
            [stateOrCallback + '_loading']: true,
            loading: true,
          });
        };

      if (!onError)
        onError = (err) => {
          console.warn(
            this.constructor.name + '> error:',
            stateOrCallback,
            err
          );
          this.setState({ [stateOrCallback + '_error']: err });
          if (useLoadingState)
            this.setState({
              [stateOrCallback + '_loading']: false,
              loading: false,
            });
        };
    } else if (typeof stateOrCallback === 'function') {
      onSuccess = stateOrCallback;
    } else {
      console.error(
        'stateOrCallback must be a string or function, not ',
        stateOrCallback
      );
    }

    return { onSuccess, onError, onStartLoading };
  }

  /**
   *  Call this on componentDidMount - replaces window.addEventListener
   */
  listenToEvent(event, callback, element = null, useCaptureOrOptions = false) {
    if (!element) element = window;
    this._unsubscribeFunctions = this._unsubscribeFunctions || [];

    let events = event.split(' ');
    for (let i = 0; i < events.length; i++)
      if (events[i]) {
        element.addEventListener(events[i], callback, useCaptureOrOptions);
        this._unsubscribeFunctions.push(() =>
          element.removeEventListener(events[i], callback)
        );
      }
  }

  /**
   *  Call this to load data from API and listen to changes realtime.
   *  Typically, only call in didMount (otherwise, remember to use unsubscribe function when needed).
   *  Returns unsubscribe function.
   *
   */
  bindCollection(resource, params, stateOrCallback, loadNow = true) {
    let callbacks = this._createLoadCallbacks(
      stateOrCallback,
      null,
      null,
      'loaded ' + resource + ' list'
    );

    return this._addUnsub(
      ApiClient.bindCollection(
        resource,
        params,
        callbacks.onSuccess,
        callbacks.onError,
        callbacks.onStartLoading,
        loadNow
      )
    );
  }

  /**
   *  Call this to load data from API and listen to changes realtime
   *  Typically, only call in didMount (otherwise, remember to use unsubscribe function when needed).
   *  Returns unsubscribe function.
   *
   */
  bindObject(resource, id, params, stateOrCallback, loadNow = true) {
    let callbacks = this._createLoadCallbacks(
      stateOrCallback,
      null,
      null,
      'loaded one ' + resource
    );

    return this._addUnsub(
      ApiClient.bindObject(
        resource,
        id,
        params,
        callbacks.onSuccess,
        callbacks.onError,
        callbacks.onStartLoading,
        loadNow
      )
    );
  }

  /**
   * Get a function to create or replace a binding to a callection from API.
   * Use this instead of bindPropToCollection if you need to handle the params changes yourself.
   *
   * @param stateOrCallback   State name or callback function. If it's a string eg. "foo" the data will be put in state.foo; Otherwise will be called on load success.
   * @param onError           If not null, called on API error (optional)
   * @param onStartLoading    If not null, called when sending API request (optional)
   * @param log               String to add to console log (optional)
   * @returns {Function}      Callback with args: (resource, params, callNow) for API call. Call with resource=null to unsubscribe.
   */
  getRebindToCollectionFunc(stateOrCallback, onError, onStartLoading, log) {
    let callbacks = this._createLoadCallbacks(
      stateOrCallback,
      onError,
      onStartLoading,
      log || 'loaded list'
    );

    let removeBinding;

    // remove binding on unmount
    this._addUnsub(() => {
      removeBinding && removeBinding(); // will call the last unbind function
      removeBinding = null;
    });

    return (resource, params, loadNow) => {
      // first remove previous binding if needed
      removeBinding && removeBinding();
      // (re)create the correct binding
      if (resource) {
        removeBinding = ApiClient.bindCollection(
          resource,
          params,
          callbacks.onSuccess,
          callbacks.onError,
          callbacks.onStartLoading,
          loadNow
        );
      } else {
        removeBinding = null;
        callbacks.onSuccess(null);
      }
    };
  }

  /**
   * Get a function to create or replace a binding to an object from API.
   * Use this instead of bindPropToObject if you need to handle the params changes yourself.
   * Call with id=null to remove binding
   *
   * @param stateOrCallback   State name or callback function. If it's a string eg. "foo" the data will be put in state.foo; Otherwise will be called on load success.
   * @param onError           If not null, called on API error (optional)
   * @param onStartLoading    If not null, called when sending API request (optional)
   * @param log               String to add to console log (optional)
   * @returns {Function}      Callback with args: (resource, id, params, callNow) for API call
   */
  getRebindToObjectFunc(stateOrCallback, onError, onStartLoading, log) {
    // TODO switch to promises ?
    let callbacks = this._createLoadCallbacks(
      stateOrCallback,
      onError,
      onStartLoading,
      log || 'loaded one'
    );

    let removeBinding; // will store the last (current) unsubscribe function

    this._addUnsub(() => {
      // will always call the right function
      removeBinding && removeBinding();
      removeBinding = null;
    });

    return (resource, id, params, callNow) => {
      removeBinding && removeBinding();
      if (id) {
        removeBinding = ApiClient.bindObject(
          resource,
          id,
          params,
          callbacks.onSuccess,
          callbacks.onError,
          callbacks.onStartLoading,
          callNow
        );
      } else {
        removeBinding = null;
        callbacks.onSuccess(null);
      }
    };
  }

  /**
   * Call this to bind params from props to API, observing both props change and server changes
   *
   * Ex: this.bindPropToCollection("Content", "content.apiParams", "topContents")
   * - will load the /Content collection with params from this.props.apiParams
   * - data will be put in this.state.topContents
   * - state.topContents_loading and state.loading will be set to true while loading
   * - state.topContents_error will be set to { code, message } in case of load error
   * Data will be loaded :
   * - when this function is called, if param is not null (use {} if you want to load with no params)
   * - each time the referenced prop changes
   * - on data change pushed by the server
   *
   * @param resource          Resource to load from API, ex. "Content"
   * @param paramsPath        Property path where params are stored, ex. "list[2].params"
   * @param stateOrCallback   State name or callback function. If it's a string eg. "foo" the data will be put in state.foo; Otherwise will be called on load success.
   * @param onError           If not null, called on API error
   * @param onStartLoading    If not null, called when sending API request
   */
  bindPropToCollection(
    resource,
    paramsPath,
    stateOrCallback,
    onError,
    onStartLoading,
    callNow
  ) {
    let bindCollection = this.getRebindToCollectionFunc(
      stateOrCallback,
      onError,
      onStartLoading
    );

    this.observeProp(paramsPath, (params) => {
      // console.debug(
      //   this.constructor.name + ' [bind] load ',
      //   resource,
      //   ' with params',
      //   params
      // );
      bindCollection(resource, params, callNow);
    });
  }

  /**
   * Call this to bind ID and params from props to API, observing both props change and server changes
   *
   * Ex: this.bindPropToCollection("Content", "content.apiParams", "topContents")
   * - will load the /Content collection with params from this.props.apiParams
   * - data will be put in this.state.topContents
   * - this.state.topContents_loading and state.loading will be set to true while loading
   * - this.state.topContents_error will be set to { code, message } in case of load error
   * Data will be loaded :
   * - when this function is called, if object ID is not null
   * - each time one of the referenced prop changes
   * - on data change pushed by the server
   *
   * @param resource          Resource to load from API, ex. "Content"
   * @param paramsOrPath      Property path where params are stored, ex. "list[2].params", or direct params value e.g. { isFree: true }
   * @param stateOrCallback   State name or callback function. If it's a string eg. "foo" the data will be put in state.foo; Otherwise will be called on load success.
   * @param onError           If not null, called on API error
   * @param onStartLoading    If not null, called when sending API request
   */
  bindPropToObject(
    resource,
    objectIdPath,
    paramsOrPath,
    stateOrCallback,
    onError,
    onStartLoading,
    callNow
  ) {
    let bindObject = this.getRebindToObjectFunc(
      stateOrCallback,
      onError,
      onStartLoading
    );

    if (typeof paramsOrPath !== 'string') {
      // params by value, just observe ID
      this.observeProp(objectIdPath, (id) => {
        // console.debug(
        //   this.constructor.name + ' [bind] load ',
        //   resource,
        //   ' with state ',
        //   objectIdPath + ' =',
        //   id,
        //   ', params',
        //   paramsOrPath
        // );
        bindObject(resource, id, paramsOrPath, callNow);
      });
    } else {
      // params in props, observe ID + params
      this.observeProps([objectIdPath, paramsOrPath], (props) => {
        let id = _.get(props, objectIdPath);
        if (id) {
          let params = _.get(props, paramsOrPath);
          // console.debug(
          //   this.constructor.name + ' [bind] load ',
          //   resource,
          //   ' with',
          //   objectIdPath + ' =',
          //   id,
          //   ',',
          //   paramsOrPath + ' =',
          //   params
          // );
          bindObject(resource, id, params, callNow);
        }
      });
    }
  }

  /**
   *  replaces window.setTimeout / setInterval
   */
  setTimer(callback, delayMs, repeat = false) {
    let timerId = (!repeat ? window.setTimeout : window.setInterval)(
      callback,
      delayMs
    );

    return this._addUnsub(() =>
      (!repeat ? window.clearTimeout : window.clearInterval)(timerId)
    );
  }

  componentWillUnmount() {
    // Unsubscribe from stores subscribed with listenToStore()
    if (this._unsubscribeFunctions)
      this._unsubscribeFunctions.forEach((u) => u());
    this._unsubscribeFunctions = [];

    this.willUnmount && this.willUnmount();
    super.componentWillUnmount();
  }

  // useful... ?
  beginLoading() {
    this.setState({ loading: true });
  }

  endLoading() {
    this.setState({ loading: false });
  }
}

// Add router context to use this.context.router.push(), .replace() etc.

export default ComponentBase;

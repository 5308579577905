// @flow
import * as React from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { debounce } from 'lodash';
import { useUserAgent } from 'next-useragent';

import { UserAgentContext } from '@/globals/contexts';

type Options = {|
  debounceTime?: number,
|};

type Returned = {|
  width: number,
  height: number,
|};

const inferWindowSizeFromUserAgent = (ua) => {
  if (ua.isTablet) {
    return {
      width: 840,
      height: 1000,
    };
  }

  if (ua.isMobile) {
    return {
      width: 420,
      height: 700,
    };
  }

  return {
    width: 1080,
    height: 1000,
  };
};

export default function useWindowSizeListener(options: Options): Returned {
  const { debounceTime = 500 } = options || {};
  const useragent = React.useContext(UserAgentContext);
  const ua = useUserAgent(useragent);

  const [windowInformation, setWindowInformation] = React.useState(
    inferWindowSizeFromUserAgent(ua)
  );

  useEffectOnce(() => {
    const onResize = () => {
      setWindowInformation({
        width:
          window.innerWidth ||
          document.documentElement?.clientWidth ||
          document.body?.clientWidth,
        height:
          window.innerHeight ||
          document.documentElement?.clientHeight ||
          document.body?.clientHeight,
      });
    };

    const debouncedOnResize = debounce(onResize, debounceTime);
    onResize();

    window.addEventListener('resize', debouncedOnResize, false);
    return () => window.removeEventListener('resize', debouncedOnResize, false);
  });

  return windowInformation;
}

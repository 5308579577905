// @flow
import type { FeatureFlags, FeatureFlag } from '@/types/models';
import type { Site } from '@/types/site';

export const getInstanceComponentFolderName = (siteId: string): string => {
  return 'template1';
};

export const getFeatureFlags = (site: Site): $Shape<FeatureFlags> => {
  const featureFlags: $Shape<FeatureFlags> = {};

  Object.keys(site)
    .filter((option) => option.startsWith('featureFlag_'))
    .forEach((fullFlagName) => {
      const flagName = ((fullFlagName
        .split('featureFlag_')
        .pop(): any): FeatureFlag);
      featureFlags[flagName] = !!site[fullFlagName];
    });

  return featureFlags;
};

export const featureEnabled = (
  featureFlag: FeatureFlag,
  instanceFeatureFlags: FeatureFlags
): boolean => !!instanceFeatureFlags[featureFlag];

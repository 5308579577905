import _ from 'lodash';
import Reflux from 'reflux';
import { InstanceActions } from '@/reflux/InstanceStore';
import API from './API';

class ApiClient {
  static binds = {};
  static watches = {};
  static uid = 1;
  static promisesCache = {};
  static channelCacheKeys = {};
  static instanceName = null;
  static DONT_CACHE = {
    Video: true,
    cart: true,
    stock: true,
    UserWatched: true, // we could also just reset cache at the right time // TODO
    'users/watched': true,
    UserQuizzAnswers: true,
    //...
  };

  static getInstanceFromStore() {
    // pourri un peu
    let instanceStore = Reflux.getGlobalState().instanceStore;
    this.instanceName = instanceStore && instanceStore.instanceName;
    this.draftMode = instanceStore && instanceStore.draftMode;
    console.debug('ApiClient init with inst: ', this.instanceName);

    InstanceActions.set.listen(
      // null sometimes because circular import -- should be fixed
      (instanceName) => this.switchInstance(instanceName)
    );

    InstanceActions.setDraft.listen((draftMode) =>
      this.setDraftMode(draftMode)
    );
  }

  static switchInstance(instanceName, draftMode) {
    if (this.instanceName !== instanceName) {
      console.debug(
        'API> set instance [' +
          (this.instanceName || '') +
          '] =>  [' +
          instanceName +
          '] & update all'
      );
      this.instanceName = instanceName;
      if (draftMode !== undefined) this.setDraftMode(draftMode);
      this.updateAll();
    }
  }

  static setDraftMode(draftMode) {
    // noinspection EqualityComparisonWithCoercionJS
    if (this.draftMode !== draftMode) {
      console.debug('API> set draft mode', draftMode);
      this.draftMode = draftMode;
      // only receive server update in draft mode (until it's more optimised)
      if (this.socket) {
        if (draftMode) {
          this.socket.open();
        } else {
          this.socket.close();
        }
      }
      this.updateAll();
    }
  }

  static updateAll() {
    this.promisesCache = {};
    _.keys(this.binds).forEach((resource) => this.onDataUpdate(resource));

    _.forEach(this.watches, (resourceWatches) =>
      _.forEach(resourceWatches, (cb) => cb && cb())
    );
  }

  static getEndPoint(resource, allowDraft = true) {
    if (resource.slice(0, 5) === 'users' || resource === 'cart') return '/';
    if (resource === 'list')
      return this.draftMode ? '/list_draft/' : '/list_data/';
    return this.draftMode && allowDraft ? '/draft/' : '/data/';
  }

  static onDataUpdate(resource) {
    this._resetResourceCache(resource);
    let endPoint = this.getEndPoint(resource);

    _.map(this.binds[resource], (bind, bindId) => {
      console.debug('API> execute bind #' + bindId, bind);
      let fullPath = this.instanceName + endPoint + bind.path;
      bind.onStartLoading && bind.onStartLoading();
      this._get(fullPath, bind.params, bindId, resource)
        .then(bind.callback, bind.onError)
        .catch((e) => this._catchError(e, fullPath, bind.onError));
    });

    _.forEach(this.watches[resource], (cb) => cb && cb());
  }

  static bindCollection(
    resource,
    params,
    callback,
    onError,
    onStartLoading,
    loadNow = true
  ) {
    return this.bindData(
      resource,
      resource,
      params,
      callback,
      onError,
      onStartLoading,
      loadNow
    );
  }

  static bindObject(
    resource,
    id,
    params,
    callback,
    onError,
    onStartLoading,
    loadNow = true
  ) {
    return this.bindData(
      resource,
      resource + '/' + encodeURIComponent(id),
      params,
      callback,
      onError,
      onStartLoading,
      loadNow
    );
  }

  static bindData(
    resource,
    path,
    params,
    callback,
    onError,
    onStartLoading,
    loadNow = true
  ) {
    let bindId = 'b' + this.uid++;
    onStartLoading && onStartLoading();

    if (loadNow && this.instanceName) {
      // TODO plus propre
      let endPoint = this.getEndPoint(resource);
      let fullPath = this.instanceName + endPoint + path; // TODO catch
      this._get(fullPath, params, 'apiclient_' + bindId, resource)
        .then(callback, onError)
        .catch((e) => this._catchError(e, fullPath, onError));
    }

    let bind = { path, params, callback, onError, onStartLoading };
    _.set(this.binds, [resource, bindId], bind);

    return () => {
      API.abort(bindId); // just in case a request is still running
      delete this.binds[resource][bindId];
    };
  }

  static watch(resource, callback) {
    let watchId = 'w' + this.uid++;
    _.set(this.watches, [resource, watchId], callback);

    return () => {
      delete this.watches[resource][watchId];
    };
  }

  static _resetResourceCache(resource) {
    console.debug('API> reset cache for all binds for', resource);
    this.promisesCache[resource] = {};
  }

  static _resetCache(fullPath, params, resource) {
    let cacheKey = fullPath + '|' + JSON.stringify(params);
    _.unset(this.promisesCache, [resource, cacheKey]);
  }

  static _get(fullPath, params, channel = null, resource = null) {
    if (this.instanceName === null) {
      //throw
      console.error('ApiClient> try to get data without instance', fullPath);
      return Promise.reject('no instance set');
    }

    if (
      typeof window === 'undefined' ||
      !resource ||
      ApiClient.DONT_CACHE[resource] ||
      ApiClient.DONT_CACHE[resource.split('/').pop()]
    ) {
      return API.get(fullPath, params, channel);
    } else {
      let cacheKey = fullPath + '|' + JSON.stringify(params);
      // Beware of immutability !!!
      const cached = _.get(this.promisesCache, [resource, cacheKey]);
      if (cached) {
        return cached;
      } else {
        const promise = API.get(fullPath, params, channel);

        _.set(this.promisesCache, [resource, cacheKey], promise);
        if (channel) this.channelCacheKeys[channel] = [resource, cacheKey];

        promise.then(null, (err) => {
          if (err && err.code === 'ABORTED') {
            console.debug('Request aborted, removing from cache', fullPath); // should already be done
          } else {
            console.debug(
              'Error in request, removing from cache',
              fullPath,
              err
            );
          }
          _.unset(this.promisesCache, [resource, cacheKey]);
          if (channel) delete this.channelCacheKeys[channel];
        });
        return promise;
      }
    }
  }

  static _post(fullPath, data, channel, resource) {
    if (this.instanceName === null) {
      console.error('ApiClient> try to post data without instance', fullPath);
      return Promise.reject('no instance set');
    }

    this._resetResourceCache(resource);
    return API.post(fullPath, data, channel);
  }

  static getObject(resource, id, params, callback, onError, channel = null) {
    if (!this.instanceName) {
      console.warn('No instance, no data', resource, id);
      onError('no instance');
      return;
    }

    let endPoint = this.getEndPoint(resource);
    let fullPath =
      this.instanceName + endPoint + resource + '/' + encodeURIComponent(id);
    this._get(fullPath, params, channel, resource)
      .then(callback, onError)
      .catch((e) => this._catchError(e, fullPath, onError));
  }

  static getCollection(resource, params, callback, onError, channel = null) {
    if (!this.instanceName) {
      console.warn('No instance, no data', resource);
      onError('no instance');
      return;
    }

    let endPoint = this.getEndPoint(resource);
    let fullPath = this.instanceName + endPoint + resource;
    this._get(fullPath, params, channel, resource)
      .then(callback, onError)
      .catch((e) => this._catchError(e, fullPath, onError));
  }

  static post(resource, id, data, callback, onError, channel = null) {
    let endPoint = this.getEndPoint(resource, false);
    let fullPath =
      this.instanceName +
      endPoint +
      resource +
      (id ? '/' + encodeURIComponent(id) : '');
    return this._post(fullPath, data, channel, resource)
      .then(callback, onError)
      .catch((e) => this._catchError(e, fullPath, onError));
  }

  static _catchError(e, path, onError) {
    console.error('Exception in API callback', path, e);
    onError &&
      onError({
        code: -1,
        message: e && e.toString && e.toString(),
      });
  }

  static abort(channel) {
    // Remove cache before aborting, otherwise new requests made after abort will get the cached version and be aborted too!
    if (this.channelCacheKeys[channel]) {
      _.unset(this.promisesCache, this.channelCacheKeys[channel]);
    }
    API.abort(channel);
  }
}

export default ApiClient;

// @flow
import type { CartStepId, CartStoreState } from '@/types/stores';

export const STEP_IDS: { [key: CartStepId]: CartStepId } = {
  CART_REVIEW: 'CART_REVIEW',
  ADDRESS_AND_PAYMENT: 'ADDRESS_AND_PAYMENT',
  SUCCESSFUL_PAYMENT: 'SUCCESSFUL_PAYMENT',
};

export const CHECKOUT_STEP = {
  CART_REVIEW: 'CART_REVIEW',
  ADDRESS_AND_PAYMENT: 'ADDRESS_AND_PAYMENT',
  SUCCESSFUL_PAYMENT: 'SUCCESSFUL_PAYMENT',
};
export const CHECKOUT_STEPS = [
  CHECKOUT_STEP.CART_REVIEW,
  CHECKOUT_STEP.ADDRESS_AND_PAYMENT,
  CHECKOUT_STEP.SUCCESSFUL_PAYMENT,
];
export const CHECKOUT_MODE = {
  SIMPLE: 'simple',
  DEFAULT: 'default',
};

export const LOCAL_STORAGE_PERSISTED_DATA = [
  'cartItems',
  'discountCode',
  'discountedItems',
];

export const INITIAL_GIFT_CART_STATE = true;

export const DEFAULT_STATE: $Shape<CartStoreState> = {
  validating: false,
  hasValidated: false,

  currentStep: STEP_IDS.CART_REVIEW,
  transitioningStep: false,

  cartAPIError: null,
  paymentError: null,

  cartItems: {},
  discountCode: '',
  discountedItems: {},
  shippingAddress: null,
  billingAddress: null,
  shippingCost: 0,
  isGift: false,
  donation: 0,
  totalDiscount: 0,
  billingAddressSameAsShippingAddress: true,
  userWantsABill: false,

  paymentIntent: null,
};

// @flow
import { useEffect } from 'react';
import KEYS from '@/constants/keyboard';

type KeyHooks = 'onEscapeKeyDown' | 'onBackspaceKeyDown' | 'onEnterKeyDown';
type Options = {
  shouldStopPropagation?: boolean | (() => boolean),
};

type UseOnKeyDown = (
  keyHooks: { [key: KeyHooks]: () => any },
  dependencyArray: Array<any>,
  options: ?Options
) => void;

const useOnKeyDown: UseOnKeyDown = (keyHooks, dependencyArray, options) => {
  const { shouldStopPropagation } = options || {};

  useEffect(() => {
    const stopEventPropagation = (e) => {
      if (
        (typeof shouldStopPropagation === 'boolean' && shouldStopPropagation) ||
        (typeof shouldStopPropagation === 'function' && shouldStopPropagation())
      ) {
        e.preventDefault();
        e.cancelBubble = true;
        if (e.stopPropagation) {
          e.stopPropagation();
        }
      }
    };

    const handleEvent = (e) => {
      if (e.keyCode === KEYS.ESCAPE && keyHooks.onEscapeKeyDown) {
        keyHooks.onEscapeKeyDown();
        return true;
      }

      if (e.keyCode === KEYS.BACKSPACE && keyHooks.onBackspaceKeyDown) {
        keyHooks.onBackspaceKeyDown();
        return true;
      }

      if (e.keyCode === KEYS.ENTER && keyHooks.onEnterKeyDown) {
        keyHooks.onEnterKeyDown();
        return true;
      }

      return false;
    };

    function onKeyDown(e) {
      const handled = handleEvent(e);
      if (handled) {
        stopEventPropagation(e);
      }
    }
    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [keyHooks, shouldStopPropagation, ...dependencyArray]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useOnKeyDown;

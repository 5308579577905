// @flow
import { atom, type RecoilState } from 'recoil';

type LoginSubscriptionModalState = {|
  titleEnd: string,
  neededRole: string,
|};

export const loginSubscriptionModalState = (atom<?LoginSubscriptionModalState>({
  key: 'loginSubscriptionModalState',
  default: null,
}): RecoilState<?LoginSubscriptionModalState>);

type NotificationCenterState = Array<{|
  text: string,
  type: 'success' | 'warning' | 'danger' | 'info',
  id: string,
  time?: number, // in ms
|}>;

export const notificationCenterState = (atom<NotificationCenterState>({
  key: 'notificationCenterState',
  default: [],
}): RecoilState<NotificationCenterState>);

// @flow
import { isObject, omit, isEmpty, values, get, pickBy, merge } from 'lodash';
import Reflux from 'reflux';

import type { CartItemEntity, File, Address } from '@/types/models';
import type { CartStoreState, CartItemOptions } from '@/types/stores';
import type { InitializeCheckout, UpdatePaymentIntent } from './types';

import { CHECKOUT_STEP, DEFAULT_STATE, CHECKOUT_STEPS } from './constants';
import { assert } from '@/helpers/invariant';
import { getFullName } from '@/helpers/models/user';
import { gtmEventCart, gtmEventPurchase } from '@/utils/googleTagManager';
import { featureEnabled } from '@/helpers/models/instance';
import {
  shippingRequired,
  getDerivedState,
  getTotal,
} from './helpers/derivedState';
import {
  emptyLocalStorage,
  updateLocalStorage,
  getStateFromLocalStorage,
} from './helpers/localStorage';
import {
  notifyCartEmpty,
  validateCart,
  createPaymentIntent,
  updatePaymentIntent,
} from './helpers/api';

import CartStoreActions from './actions';

export default class CartStore extends Reflux.Store {
  state: CartStoreState;
  setState: ($Shape<CartStoreState>) => void;

  constructor() {
    super();
    this.listenables = CartStoreActions;

    const loadedState = getStateFromLocalStorage();

    this.state = {
      ...DEFAULT_STATE,
      ...loadedState,
    };
  }

  // Empty the cart and localStorage
  empty() {
    this.setState(DEFAULT_STATE);
    notifyCartEmpty(this.state.instanceId);
    emptyLocalStorage();
  }

  // Validate cart with backend
  async validateCart(): Promise<any> {
    const { paymentIntent, currentStep } = this.state;
    this.setState({ validating: true });

    try {
      const { errors, shippingCost, totalDiscount } = await validateCart(
        this.state
      );

      if (!isEmpty(errors)) {
        this.setState({ validating: false, cartAPIError: errors });
        return;
      }

      this.setState({
        shippingCost,
        totalDiscount,
        validating: false,
      });

      if (paymentIntent && currentStep === CHECKOUT_STEP.ADDRESS_AND_PAYMENT) {
        this.goToPreviousStep();
      }
    } finally {
      this.setState({ validating: false });
    }
  }

  // Feed CartStore with checkout options and logged in user if any
  // Triggered on checkout component moun
  initializeCheckout: InitializeCheckout = ({ user, checkoutOptions }) => {
    const { shippingAddress, billingAddress, featureFlags, giftInfo } =
      this.state;
    const giftCardEnabled = featureEnabled('giftCard', featureFlags);

    const newState: $Shape<CartStoreState> = {
      currentStep: CHECKOUT_STEP.CART_REVIEW,
    };

    newState.cartUser = user;
    newState.checkoutOptions = checkoutOptions;

    if (!!user) {
      newState.billingAddress = billingAddress ?? user.address;
      newState.shippingAddress = shippingAddress ?? user.address;
    }

    if (!giftCardEnabled && giftInfo?.giftCard) {
      newState.giftInfo = {
        ...giftInfo,
        giftCard: false,
      };
    }

    this.setState(newState);

    this.validateCart();
  };

  async _transitionFromCartReviewToPayment() {
    const { hasError } = getDerivedState(this.state);
    this.setState({ hasValidated: true });

    try {
      await this.validateCart();
    } catch (error) {
      this.setState({ cartAPIError: error });
      return;
    }

    if (hasError) return;

    try {
      const intent = await createPaymentIntent(this.state);
      this.setState({
        paymentIntent: intent,
        currentStep: CHECKOUT_STEP.ADDRESS_AND_PAYMENT,
        transitioningStep: false,
      });
    } catch (error) {
      this.setState({
        paymentError: error,
        transitioningStep: false,
      });
    }
  }

  /* STEP MANAGEMENT BEGIN */
  goToPreviousStep() {
    const { currentStep } = this.state;

    assert(
      currentStep === CHECKOUT_STEP.ADDRESS_AND_PAYMENT,
      `Unrecognized current step in CartStore: ${currentStep || ''}`
    );

    this.setState({
      currentStep: CHECKOUT_STEPS[CHECKOUT_STEPS.indexOf(currentStep) - 1],
      paymentIntent: null,
    });
  }

  async goToNextStep() {
    const { currentStep } = this.state;
    this.setState({ transitioningStep: true });

    assert(
      currentStep === CHECKOUT_STEP.CART_REVIEW,
      `Unrecognized current step in CartStore: ${currentStep || ''}`
    );

    if (currentStep === CHECKOUT_STEP.CART_REVIEW) {
      await this._transitionFromCartReviewToPayment();
      this.setState({ transitioningStep: false });
    } else {
      throw new Error(`Cannot transition from step ${currentStep || ''}`);
    }
  }
  /* STEP MANAGEMENT END */

  /* Payment logic, triggered from payment step, after Stripe card valid submission */
  updatePaymentIntent: UpdatePaymentIntent = async (intent, options) => {
    const { onSuccess } = options || {};

    try {
      const paymentIntent = await updatePaymentIntent(this.state);

      this.setState({
        paymentIntent,
        currentStep: CHECKOUT_STEP.SUCCESSFUL_PAYMENT,
      });

      const { donation, cartItems, shippingCost, totalDiscount } = this.state;
      const total = getTotal(this.state);
      gtmEventPurchase(
        values(cartItems),
        donation,
        get(paymentIntent, 'metadata.paymentId', intent.id),
        total,
        shippingCost,
        totalDiscount ? 'discount' : undefined
      );

      this.empty();
      onSuccess && (await onSuccess(paymentIntent));
    } catch (e) {
      // Handle onError ?
      throw new Error(e);
    }
  };

  setUserPrice(entityId: string, userPrice: number) {
    const { cartItems } = this.state;
    const newItems = {
      ...cartItems,
      [entityId]: {
        ...cartItems[entityId],
        quantity: cartItems[entityId].quantity || 1,
        userPrice,
      },
    };
    this.setState({ cartItems: newItems });
  }

  /* SETTERS BEGIN */
  async addItem(
    entity: CartItemEntity<*>,
    { quantity, userPrice }: {| quantity: number, userPrice?: number |} = {
      quantity: 1,
      userPrice: undefined,
    }
  ) {
    const { cartItems, isGift } = this.state;
    let newItems = { ...cartItems };

    if (!entity) return;
    if (cartItems[entity._id]) return;

    if (entity._cls === 'SubscriptionFormula' && cartItems[entity._id]) {
      newItems = {
        ...pickBy(cartItems, (item, itemId) => itemId !== entity._id),
        [entity._id]: {
          entity,
          quantity,
          userPrice,
        },
      };
    } else {
      const newItemQuantity = (cartItems[entity._id]?.quantity || 0) + quantity;
      newItems = {
        ...cartItems,
        [entity._id]: {
          entity,
          quantity: newItemQuantity,
          userPrice,
        },
      };
    }

    const requiresShipping = shippingRequired(newItems);
    const giftable = Object.keys(newItems).some(
      (itemId) => newItems[itemId].entity.giftable
    );

    this.setState({
      cartItems: newItems,
      requiresShipping,
      isGift: giftable && isGift,
    });

    await this.validateCart();

    updateLocalStorage(this.state);

    gtmEventCart(true, [{ entity, quantity, userPrice }]);
  }

  addItemOptions(itemId: string, optionShape: $Shape<CartItemOptions>) {
    const { cartItems } = this.state;
    const item = cartItems[itemId];

    if (!item) return;

    this.setState({
      cartItems: merge({}, cartItems, {
        [itemId]: {
          options: {
            ...optionShape,
          },
        },
      }),
    });

    updateLocalStorage(this.state);
  }

  removeItems(itemIds: Array<string>): void {
    itemIds
      .filter((itemId) => !!itemId)
      .forEach((itemId) => this.removeItem(itemId));
  }

  async removeItem(itemId: string): Promise<void> {
    const { cartItems, isGift, instanceId } = this.state;
    const newItems = omit(cartItems, itemId);
    const requiresShipping = shippingRequired(newItems);
    const giftable = Object.keys(newItems).some(
      (itemId) => newItems[itemId].entity.giftable
    );

    this.setState({
      cartItems: newItems,
      requiresShipping,
      isGift: giftable && isGift,
    });

    await this.validateCart();

    updateLocalStorage(this.state);

    if (isEmpty(newItems)) {
      notifyCartEmpty(instanceId);
    }
  }

  setIsGift(newIsGift: boolean): void {
    const { cartUser: user, giftInfo, featureFlags } = this.state;
    const giftCardEnabled = featureEnabled('giftCard', featureFlags);

    this.setState({
      isGift: newIsGift,
      giftInfo: giftInfo || {
        giftCard: giftCardEnabled,
        senderName: user ? getFullName(user) : '',
        sendDate: null,
        recipient: {
          email: '',
          name: '',
        },
      },
    });

    updateLocalStorage(this.state);
  }

  async addJustificativeToItem(id: string, justificative: File): Promise<*> {
    const { cartItems } = this.state;

    this.setState({
      cartItems: {
        ...cartItems,
        [id]: {
          ...cartItems[id],
          justificative,
        },
      },
    });
  }

  updateQuantity(id: string, newQuantity: number): void {
    const { instanceId, cartItems } = this.state;
    let newItems = cartItems;

    if (newQuantity === 0) {
      newItems = omit(cartItems, id);
    } else {
      newItems = {
        ...cartItems,
        [id]: {
          ...cartItems[id],
          quantity: newQuantity,
        },
      };
    }

    this.setState({
      cartItems: newItems,
    });

    if (isEmpty(newItems)) {
      notifyCartEmpty(instanceId);
    }
  }

  setDonation(newDonation: number) {
    const {
      checkoutOptions: { maxDonation },
    } = this.state;
    const computedNewDonation =
      (!!maxDonation ? Math.min(newDonation, maxDonation) : newDonation) || 0;

    this.setState({
      donation: computedNewDonation,
    });

    updateLocalStorage(this.state);
  }

  async setAddress(
    addressType: 'shippingAddress' | 'billingAddress',
    newAddress: Address
  ) {
    const currentAddress = Object.assign({}, this.state[addressType]);

    if (!isObject(newAddress)) throw new Error('New address is not an object');

    // $FlowIgnore
    this.setState({ [addressType]: newAddress });

    const toRevalidate =
      newAddress?.country !== currentAddress?.country ||
      newAddress?.postalCode !== currentAddress?.postalCode;

    if (toRevalidate) await this.validateCart();

    updateLocalStorage(this.state);
  }

  forceSetState(
    statePiece: $Shape<CartStoreState>,
    options?: { validate?: boolean, shouldUpdateLocalStorage: boolean }
  ) {
    const { validate = false, shouldUpdateLocalStorage } = options || {
      validate: false,
      shouldUpdateLocalStorage: true,
    };
    this.setState(statePiece);

    if (shouldUpdateLocalStorage) updateLocalStorage(this.state);

    if (validate) this.validateCart();
  }
  /* SETTERS END */
}

CartStore.id = 'CartStore';

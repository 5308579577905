// @flow
import type { CartDerivedErrors, CartStoreState } from '@/types/stores';

import { isGiftable, shippingRequired } from './derivedState';
import { isValidEmail } from '@/utils/misc';

export const ERROR_MESSAGES = {
  GENERAL: {
    NO_CART_ITEMS: "Vous n'avez pas d'articles dans votre panier.",
  },
  CART_ITEMS: {
    MISSING_JUSTIFICATIVE: 'Justificatif manquant',
    NOT_GIFTABLE: 'Ne peut pas être offert en cadeau',
    MISSING_FIRST_ISSUE:
      'Vous devez sélectionner la première revue que vous souhaitez recevoir',
  },
  ADDRESS: {
    INCOMPLETE_ADDRESS: "L'adresse n'est pas complète",
    INVALID_ADDRESS: 'Veuillez valider votre adresse',
  },
  GIFT: {
    GIFT_MISSING_SENDER_NAME: "Le nom de l’envoyeur n'est pas renseigné",
    GIFT_MISSING_RECIPIENT_EMAIL:
      "L'email de destination pour le cadeau n'est pas renseigné",
    GIFT_MISSING_RECIPIENT_NAME:
      'Vous devez rentrer le nom du destinataire du cadeau',
    SOME_PRODUCTS_ARE_NOT_GIFTABLE:
      'Certains produits sélectionnés ne peuvent pas être offert en cadeau. Retirer les éléments indiqués avant de recommencer',
    INVALID_EMAIL: "L'email du destinataire n'est pas valide",
  },
};

export const getEmptyErrors = (
  cartState: CartStoreState
): CartDerivedErrors => {
  const { cartItems } = cartState;
  const cartItemErrors = {};
  Object.keys(cartItems).forEach((itemId) => (cartItemErrors[itemId] = []));

  return {
    gift: [],
    cartItems: cartItemErrors,
    shippingAddress: [],
    billingAddress: [],
    general: [],
  };
};

export const getErrors = (cartState: CartStoreState): CartDerivedErrors => {
  return {
    gift: getGiftError(cartState),
    cartItems: getCartItemsError(cartState),
    shippingAddress: getShippingAddressError(cartState),
    billingAddress: getBillingAddressError(cartState),
    general: getGeneralErrors(cartState),
  };
};

const getGiftError = (cartState: CartStoreState): Array<string> => {
  if (!cartState.isGift || !cartState.giftInfo) return [];

  const { cartItems, giftInfo } = cartState;
  const { giftCard, senderName, recipient } = giftInfo;
  const allGiftable = isGiftable(cartItems);
  const errors = [];

  if (!allGiftable)
    errors.push(ERROR_MESSAGES.GIFT.SOME_PRODUCTS_ARE_NOT_GIFTABLE);

  if (giftCard) {
    if (!recipient.name)
      errors.push(ERROR_MESSAGES.GIFT.GIFT_MISSING_RECIPIENT_NAME);
    if (!senderName) errors.push(ERROR_MESSAGES.GIFT.GIFT_MISSING_SENDER_NAME);
  } else {
    if (!senderName) errors.push(ERROR_MESSAGES.GIFT.GIFT_MISSING_SENDER_NAME);
    if (!recipient.email)
      errors.push(ERROR_MESSAGES.GIFT.GIFT_MISSING_RECIPIENT_EMAIL);
    if (recipient.email && !isValidEmail(recipient.email))
      errors.push(ERROR_MESSAGES.GIFT.INVALID_EMAIL);
  }

  return errors;
};

export const getCartItemsError = (
  cartState: CartStoreState
): { [entityId: string]: Array<string> } => {
  const { cartItems } = cartState;
  const errors = {};
  Object.keys(cartItems).forEach(
    (itemId) => (errors[itemId] = getCartItemError(itemId, cartState))
  );

  return errors;
};

const getCartItemError = (
  id: string,
  cartState: CartStoreState
): Array<string> => {
  const { isGift, cartItems, checkoutOptions: options } = cartState;
  const item = cartItems[id];
  if (!item?.entity) return [];

  const errors = [];
  if (item.entity.requireJustif && !item.justificative)
    errors.push(ERROR_MESSAGES.CART_ITEMS.MISSING_JUSTIFICATIVE);
  if (isGift && !item.entity.giftable)
    errors.push(ERROR_MESSAGES.CART_ITEMS.NOT_GIFTABLE);
  if (
    options?.firstIssue &&
    item.entity?._cls === 'SubscriptionFormula' &&
    !item.options?.firstIssue
  )
    errors.push(ERROR_MESSAGES.CART_ITEMS.MISSING_FIRST_ISSUE);

  return errors;
};

export const getGeneralErrors = (cartState: CartStoreState): Array<string> => {
  const { cartItems } = cartState;
  const errors = [];

  if (Object.keys(cartItems).length === 0)
    errors.push(ERROR_MESSAGES.GENERAL.NO_CART_ITEMS);

  return errors;
};

export const getShippingAddressError = (
  cartState: CartStoreState
): Array<string> => {
  const { cartItems, shippingAddress } = cartState;
  const requiresShipping = shippingRequired(cartItems);

  if (!requiresShipping) return [];

  const errors = [];

  if (!shippingAddress?._isComplete) {
    errors.push(ERROR_MESSAGES.ADDRESS.INCOMPLETE_ADDRESS);
    return errors;
  }

  if (!shippingAddress._isValid)
    errors.push(ERROR_MESSAGES.ADDRESS.INVALID_ADDRESS);

  return errors;
};

export const getBillingAddressError = (
  cartState: CartStoreState
): Array<string> => {
  const {
    billingAddress,
    userWantsABill,
    billingAddressSameAsShippingAddress,
  } = cartState;

  let needsValidation = false;
  if (!shippingRequired && !userWantsABill) needsValidation = false;
  if (shippingRequired && billingAddressSameAsShippingAddress)
    needsValidation = false;
  if (!shippingRequired && userWantsABill) needsValidation = true;
  if (shippingRequired && !billingAddressSameAsShippingAddress)
    needsValidation = true;

  if (!needsValidation) return [];

  const errors = [];

  if (!billingAddress?._isComplete) {
    errors.push(ERROR_MESSAGES.ADDRESS.INCOMPLETE_ADDRESS);
    return errors;
  }

  if (!billingAddress._isValid)
    errors.push(ERROR_MESSAGES.ADDRESS.INVALID_ADDRESS);

  return errors;
};

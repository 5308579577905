import Reflux from 'reflux';

export var GlobalPlaybackStoreActions = Reflux.createActions(['priorityPlay']);

export default class GlobalPlaybackStore extends Reflux.Store {
  constructor(initialState = {}) {
    super();

    this.state = {
      priorityPlaybackPlaying: false,
      ...initialState,
    };

    this.listenables = GlobalPlaybackStoreActions;
  }

  priorityPlay(playing) {
    this.setState({ priorityPlaybackPlaying: playing });
  }
}

import * as Sentry from '@sentry/browser';
import Reflux from 'reflux';

import API from '@/utils/API';
import ApiClient from '@/utils/ApiClient';
import {
  setGtmUserStatus,
  gtmEventLogin,
  gtmEventLogout,
} from '@/utils/googleTagManager';

// TODO use promises

export const UserActions = Reflux.createActions([
  'set',
  'setSession',
  'logout',
  'login',
  'register',
  'update',
  'checkLoginExists',
  'checkLoginConnectable',
  'checkNickNameExists',
  'subscribe',
  'getAccountInfo',
  'addPaymentMethod',
  'setDefaultPaymentMean',
  'deletePaymentMean',
  'loadPaymentMeans',
  'addPaymentSource',
  'incAnonymousViewCount',
  'incFreemiumViewCount',
]);

export default class UserStore extends Reflux.Store {
  //static id = "UserStore";

  constructor() {
    super();

    this.state = {
      user: null,
      userLoaded: false,
    };

    this.listenables = UserActions;
  }

  _getInstanceName() {
    // TODO better
    let instanceStore = Reflux.getGlobalState().instanceStore;
    return instanceStore && instanceStore.instanceName;
  }

  set(user) {
    let userChanges =
      (this.state.user && this.state.user._id) !== (user && user._id);

    !!user
      ? Sentry.setUser({
          email: user.login,
          id: user._id,
          name: user.name,
          premium: user.isPremium,
          instance: this._getInstanceName(),
        })
      : Sentry.configureScope((scope) => scope.setUser(null));

    this.setState({ user, userLoaded: true });

    if (userChanges) ApiClient.updateAll();
  }

  async logout() {
    const instanceId = this._getInstanceName();
    await API.post(`${instanceId}/users/logout`, null);
    const session = await API.get(`${instanceId}/users/sessions/initiate`);

    UserActions.setSession(session);

    UserActions.set(null);
    ApiClient.updateAll();
    gtmEventLogout();
    setGtmUserStatus(null);
  }

  async login(loginInfo, callback) {
    try {
      const instanceId = this._getInstanceName();
      const user = await API.post(`${instanceId}/users/login`, {
        ...loginInfo,
        l: loginInfo.login.length,
      });
      const session = await API.get(`${instanceId}/users/sessions/initiate`);

      UserActions.setSession(session);
      UserActions.set(user);
      callback && callback(null);
      ApiClient.updateAll();
      gtmEventLogin();
      setGtmUserStatus(user);
    } catch (e) {
      callback(e);
    }
  }

  resetPass() {} // TODO

  async register(userPayload, context = {}, callback) {
    try {
      const instanceId = this._getInstanceName();
      const user = await API.post(`${instanceId}/users/create`, {
        user: userPayload,
        context,
      });
      const session = await API.get(`${instanceId}/users/sessions/initiate`);
      UserActions.setSession(session);
      UserActions.set(user);
      callback && callback(null, user);
      ApiClient.updateAll();
    } catch (e) {
      callback(e);
    }
  }

  update(user, callback) {
    user._id &&
      API.post(this._getInstanceName() + '/users/modify', { user })
        .then((user) => {
          UserActions.set(user);
          callback && callback(null);
          //ApiClient.updateAll();
        })
        .catch(callback);
  }

  checkLoginExists(login, callback) {
    API.get(
      this._getInstanceName() + '/users/loginExists',
      { login },
      'check-login'
    ).then(callback);
    //.catch(callback)
  }
  checkNickNameExists(nickname, callback) {
    API.get(
      this._getInstanceName() + '/users/nicknameExists',
      { nickname },
      'check-nickname'
    ).then(callback);
    //.catch(callback)
  }

  /**
   * Get status : ok  / paswword_to_set / not_found
   *
   * @param login
   * @param callback
   */
  checkLoginConnectable(login, callback) {
    API.get(
      this._getInstanceName() + '/users/status',
      { login },
      'check-connectable'
    ).then(callback);
  }

  getAccountInfo(resource, callback) {
    API.get(this._getInstanceName() + '/users/' + resource) // todo +S ?
      .then(callback)
      .catch((err) => callback && callback(null, err));
  }

  addPaymentMethod(paymentMethodId, callback) {
    // TODO post paymentMeans ?
    API.post(this._getInstanceName() + '/users/addPaymentMethod', {
      id: paymentMethodId,
      setAsDefault: true,
    })
      .then((res) => {
        UserActions.loadPaymentMeans();
        callback && callback(res);
      })
      .catch((err) => callback && callback(null, err));
  }

  addPaymentSource(id, clientSecret, callback) {
    API.post(this._getInstanceName() + '/users/addSource', {
      id,
      clientSecret,
    })
      .then((res) => {
        UserActions.loadPaymentMeans();
        callback && callback(res);
      })
      .catch((err) => callback && callback(null, err));
  }

  async loadPaymentMeans(callback) {
    try {
      const res = await API.get(
        this._getInstanceName() + '/users/paymentMeans',
        null
      );

      this.setState({ userPaymentMeans: res });
      callback && callback(res);
    } catch (err) {
      callback && callback(null, err);
      console.error(err);
    }
  }

  async setDefaultPaymentMean(paymentMean, callback) {
    try {
      await API.post(
        this._getInstanceName() + '/users/setDefaultPaymentMean',
        paymentMean
      );
      UserActions.loadPaymentMeans(callback);
    } catch (err) {
      callback && callback(null, err);
      console.error(err);
    }
  }

  deletePaymentMean(paymentMean, callback) {
    if (paymentMean.object === 'payment_method') {
      ApiClient.post(
        // TODO method delete
        'users',
        'deletePaymentMethod',
        {
          id: paymentMean.id,
        },
        (res) => {
          // res discarded
          UserActions.loadPaymentMeans(callback);
        },
        (err) => callback && callback(null, err)
      );
    } else {
      ApiClient.post(
        'users',
        'deleteSource',
        {
          id: paymentMean.id,
          objectType: paymentMean.object, // not used
        },
        (res) => {
          // res discarded
          UserActions.loadPaymentMeans(callback);
        },
        (err) => callback && callback(null, err)
      );
    }
  }

  setSession(session) {
    this.setState({ session });
  }
}

UserStore.id = 'UserStore'; // https://github.com/reflux/refluxjs/tree/master/docs/stores#global-state

import Reflux from 'reflux';
import nookies from 'nookies';
import API2 from '@/utils/API2';
import config from '@/utils/config';
import { isDev } from '@/helpers/env';

import { goToPage } from '@/PUFComponents/base/navigation/PageLinkV1';

export const InstanceActions = Reflux.createActions(['set', 'setDraft']);

export default class InstanceStore extends Reflux.Store {
  static INSTANCE_INITIAL_STATE = {};

  constructor() {
    super();

    console.debug('%%%%%%%%%%%%%%%%%% <---------- read INSTANCE_INITIAL_STATE');

    this.state = {
      instanceList: [], // used by AdminFooter
      instanceName: null,
      draftMode: false,
      allowSwitchInstance: true, // always true
      ...InstanceStore.INSTANCE_INITIAL_STATE,
    };

    this.listenables = InstanceActions;

    // initial set is now done in _app... but id doesn't work
    //InstanceActions.set(LocalStore.enabled && LocalStore.get("instanceName") || "test1");

    // KOMODO TEASER
    /*
        const prefix = "";
        API.get("global/data/Site", {domain: "komodo"}).then(sites =>
            this.setState({
                instanceList: sites.map(s => ({value: s._id, label: prefix + s._id + " (" +s.domain +")"})),
            })
        );
        InstanceActions.set("komodo");
        */

    let instanceCookieInBrowser = nookies.get({}).instanceName;

    if (instanceCookieInBrowser) {
      console.debug(
        'got instance from client cookie: ',
        instanceCookieInBrowser
      );
      this.state.instanceName = instanceCookieInBrowser;
      InstanceActions.set(instanceCookieInBrowser); // Won't do anything server side :(
    } else {
      //InstanceActions.set("test1");// really useless
    }

    let draftCookie = nookies.get({}).draftMode;
    if (draftCookie) {
      InstanceActions.setDraft(draftCookie === 'true');
    }

    const prefix =
      config.PUF_ENV === 'development'
        ? ''
        : '[' + (config.PUF_ENV || 'L') + '] ';

    let host = (process.browser && window.location.hostname) || null;

    const api = new API2();
    if (typeof window === 'undefined') {
      // Server-side SSR
      api.setServerMode();
    }

    if (isDev()) {
      api.get('global/data/Site', { domain: host }).then((sites) =>
        this.setState({
          instanceList: sites.map((s) => ({
            id: s._id,
            value: s._id,
            name: s._id,
            label: prefix + s._id + ' (' + s.domain + ')',
            domain: s.domain,
          })),
          // re-set instance name here also if sites.length===1 ?
        })
      );
    }
  }

  onSet(instanceName, ctxSSR) {
    if (instanceName !== this.state.instanceName) {
      console.debug(
        'Set instance',
        process.browser ? 'client' : '',
        ctxSSR ? 'SSR' : '',
        instanceName
      );
      this.setState({
        instanceName,
        instance: this.instanceList.find(
          (instance) => instance.name === instanceName
        ),
      });
      nookies.set(ctxSSR, 'instanceName', instanceName, { path: '/' });
      goToPage('home');
    }
  }

  onSetDraft(isDraft) {
    if (isDraft !== this.state.draftMode) {
      console.debug('Set draft mode', isDraft);
      nookies.set(null, 'draftMode', isDraft.toString(), { path: '/' });
      this.setState({ draftMode: isDraft });
    }
  }
}

InstanceStore.id = 'instanceStore'; // https://github.com/reflux/refluxjs/tree/master/docs/stores#global-state

// @flow
import { useContext } from 'react';

import { PageContext } from '@/globals/contexts';

export type Urls = {|
  fullUrl: string,
  baseUrl: string,
|};

export default function useUrl(): Urls {
  const pageContext = useContext(PageContext);

  if (!pageContext) throw new Error('Missing site context');

  return {
    fullUrl: pageContext.fullUrl,
    baseUrl: pageContext.baseUrl,
  };
}

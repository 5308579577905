// Implemented especially for ciceron

import Reflux from 'reflux';
import { UserStore } from '@/reflux';

// --------------------------------------- GTM ----------------------------------
const GTM_STANDARD_EVENT_NAME = 'magNumEvent';
const GTM_ECOMMERCE_EVENT_NAME = 'magNumEcommerce';
var Gtm;
var gtmEventsBeforeInit = [];

export const initGtm = (gtmTrackingId, user) => {
  console.debug('GTM init', gtmTrackingId);
  import('react-gtm-module').then((tm) => {
    console.debug('GTM init ok');
    Gtm = tm;
    setGtmUserStatus(user || Reflux.getGlobalState()[UserStore.id].user);
    Gtm.initialize({
      gtmId: gtmTrackingId,
    });
    gtmEventsBeforeInit.forEach(gtmEvent);
  });
};

export const gtmEvent = (data) => {
  if (Gtm) {
    console.debug('GTM DL>', data);
    try {
      // this can crash apparently!
      Gtm && Gtm.dataLayer({ dataLayer: data });
    } catch (e) {
      console.error('GTM Exception', e);
    }
  } else {
    console.debug('GTM DL (before init))>', data);
    gtmEventsBeforeInit.push(data);
  }
};

async function _digest(message) {
  if (window.TextEncoder && window.crypto && window.crypto.subtle) {
    const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join(''); // convert bytes to hex string
    return hashHex;
  }
  return message;
}

// same as window.dataLayer.push
export const setGtmUserStatus = (user) =>
  gtmEvent(
    user
      ? {
          userConnectionStatus: 'Utilisateur connecté',
          userId: user._id,
          userProfile: user.isPremium ? 'Compte abonné' : 'Compte simple',
          userHashedId: _digest(user._id),
          userNewsletterStatus: user.receiveNewsletter
            ? 'Abonné newsletter'
            : 'Pas abonné newsletter',
        }
      : {
          userConnectionStatus: 'Utilisateur non identifié',
        }
  );

export const gtmUserEvent = (action) =>
  gtmEvent({
    event: GTM_STANDARD_EVENT_NAME,
    eventCategory: 'Utilisateur',
    eventAction: action,
    eventLabel: action,
  });

export const gtmEventNewsletterSubscribe = () =>
  gtmUserEvent('Abonnement newsletter');

export const gtmEventLogin = () => gtmUserEvent('Connexion');

export const gtmEventRegister = () => gtmUserEvent('Création de compte');

export const gtmEventLogout = () => gtmUserEvent('Déconnexion');

export const gtmVideoEvent = (title, videoMode, percent, author, affiliate) =>
  gtmEvent({
    event: GTM_STANDARD_EVENT_NAME,
    eventCategory: 'Vidéo ' + percent + '%', // 0 / 25 / 50 / 75 / 90
    eventAction: 'Vidéo ' + videoMode + ' - ' + title, // "gratuite si inscription" non utilisé
    eventLabel: author + (affiliate ? ' - ' + affiliate : ''), // auteur et affilié de la vidéo
  });

/**
 *
 * @param action  'productDetail' / 'cartProductAdded' / 'cartProductRemoved' / 'checkoutStep' / 'orderConfirmation'
 * @param products
 * @param donation
 * @param additionalData
 */
export const gtmEventProduct = (action, products, donation, additionalData) => {
  let productData = (products || []).map((p) => ({
    // string - nom du produit
    productName: p.entity.cartLabel || p.entity.label,
    // string- Id interne du produit, obligatoire
    productId: p.entity.analyticsRef || p.entity._id,
    // floating - Prix Hors Taxes du produit ou de l'abonnement
    productUnitPrice: p.userPrice || p.entity.price,
    // string - Type de produit :abonnement / don / bien
    productCategory1: 'bien',
    // string - Typologie de produit "produit physique" / "produit virtuel" / "produit physique + virtuel"
    productCategory2: p.entity.requiresShipping
      ? 'produit physique'
      : 'produit virtuel',

    // only for the following 'eventCommand': 'cartProductAdded', 'cartProductRemoved', 'checkoutStep', 'orderConfirmation'
    productQuantity: action !== 'productDetail' ? p.quantity : undefined,

    //shipping_cost     : p.shippingCost,
    //user_tip          : !_.isNil(p.userPrice) ? p.userPrice - (p.entity.price || 0) : undefined,
  }));

  if (donation)
    productData.push({
      productName: 'Don lors de la commande',
      productId: 'donation',
      productUnitPrice: donation,
      productCategory1: 'don',
      productCategory2: 'produit virtuel',
      productQuantity: 1,
    });

  gtmEvent({
    event: GTM_ECOMMERCE_EVENT_NAME,
    currencyCode: 'EUR', // https://en.wikipedia.org/wiki/ISO_4217

    eventCommand: action,
    // [eventCommand] == 'productDetail' -> Un produit est vu
    // [eventCommand] == 'cartProductAdded' -> Un produit est ajouté au panier
    // [eventCommand] == 'cartProductRemoved' -> un produit est retiré du panier
    // [eventCommand] == 'checkoutStep' -> une étape de checkout s'éxécute
    // [eventCommand] == 'orderConfirmation' -> confirmation de commande, le reçu

    // here is the standard ProductList of each individual productObject:
    productList: productData,

    ...additionalData,
  });
};

export const gtmEventCart = (add, products, donation) =>
  gtmEventProduct(
    add ? 'cartProductAdded' : 'cartProductRemoved',
    products,
    donation
  );

export const gtmEventCheckout = (products, step) =>
  gtmEventProduct('checkoutStep', products, 0, {
    checkoutStepNumber: step, // Whole positive number, starts with 1
    checkoutStepName:
      { 1: 'Login / Register', 2: 'Récapitulatif', 3: 'Paiement' }[step] || '', // string
    // 1 -> Récapitulatif
    // 2 -> Paiement
  });

export const gtmEventPurchase = (
  products,
  donation,
  paymentId,
  amount,
  shippingCost,
  discountName,
  siteName
) =>
  gtmEventProduct('orderConfirmation', products, donation, {
    // only when 'eventCommand' == orderConfirmation
    orderId: paymentId, // string - l'Id de la commande
    orderRevenue: amount, // floating number - revenu hors taxe de la commande
    orderTax: 0, // floating number - Taxes présentes dans la commande (0 si inconnu)
    orderShippingCost: shippingCost || 0, // floating number - frais de livraison // 0 si produit virtuel
    orderShopName: siteName, // string - Nom de la boutique ou du site Internet
    orderCoupon: discountName, // string - code promo utilisé pour la commande   // laisser undefined si vide
  });

const gtmEventFormula = (action, formula, userPrice, additionnalData) =>
  gtmEvent({
    event: GTM_ECOMMERCE_EVENT_NAME,
    eventCommand: action,
    currencyCode: 'EUR', // https://en.wikipedia.org/wiki/ISO_4217
    // here is the standard ProductList of each individual productObject:
    productList: [
      {
        productName: formula.label,
        productId: formula._id,
        productUnitPrice: userPrice || formula.price,
        productCategory1: 'abonnement',
        productCategory2: 'produit virtuel',
        productQuantity: 1,
      },
    ],
    ...additionnalData,
  });

export const gtmEventSubscribe = (
  formula,
  finalPrice,
  userPrice,
  paymentId,
  discountName,
  siteName
) =>
  gtmEventFormula('orderConfirmation', formula, userPrice, {
    orderId: paymentId, // string - l'Id de la commande
    orderRevenue: finalPrice, // floating number - revenu hors taxe de la commande
    orderTax: 0, // floating number - Taxes présentes dans la commande (0 si inconnu)
    orderShopName: siteName, // string - Nom de la boutique ou du site Internet
    orderCoupon: discountName, // string - code promo utilisé pour la commande   // laisser undefined si vide
  });

export const gtmEventCartFormula = (add, formula, discountName, siteName) =>
  gtmEventFormula(
    add ? 'cartProductAdded' : 'cartProductRemoved',
    formula,
    null,
    {
      orderShopName: siteName, // string - Nom de la boutique ou du site Internet
      orderCoupon: discountName, // string - code promo utilisé pour la commande   // laisser undefined si vide
    }
  );

export const consentEvent = ({
  performanceCookieValue,
  targetingCookieValue,
  socialCookieValue,
}: {|
  performanceCookieValue: 1 | 0,
  targetingCookieValue: 1 | 0,
  socialCookieValue: 1 | 0,
|}) =>
  gtmEvent({
    event: 'consent_event',
    ga_cookie_settings: `p${performanceCookieValue}t${targetingCookieValue}s${socialCookieValue}`,
    consent_performance: performanceCookieValue,
    consent_targeting: targetingCookieValue,
    consent_social: socialCookieValue,
  });

import * as React from 'react';
import { isNumber } from 'lodash';

const DEFAULT_OPTIONS = {
  integerOnly: false,
  min: undefined,
  max: undefined,
};

export default function useNumberState(options) {
  const { integerOnly, min, max } = options || DEFAULT_OPTIONS;

  const [number, setNumber] = React.useState(min);

  React.useEffect(() => {
    const parsedNumber = parseFloat(number, 10);

    if (parsedNumber !== 0 && !parsedNumber) return;

    if (isNumber(parsedNumber)) {
      if (!!min && parsedNumber < min) {
        setNumber(min);
        return;
      }

      if (!!max && parsedNumber > max) {
        setNumber(max);
        return;
      }

      if (integerOnly && parsedNumber % 1 !== 0) {
        setNumber(Math.round(parsedNumber));
        return;
      }
    }
  }, [number, integerOnly, max, min]);

  return [number, setNumber];
}

// @flow

export default function invariant(assertion: boolean, failureMessage: string) {
  if (!assertion) {
    throw new Error(`Failed assertion: ${failureMessage}`);
  }
}

export function assert<T>(o: ?T, message: string): T {
  invariant(!!o, message);
  return o;
}

export function assertIsDefined<T>(o?: T, message: string): T {
  invariant(typeof o !== 'undefined', message);
  return o;
}

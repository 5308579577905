// @flow

const KEYS: { [key: string]: number } = {
  ENTER: 13,
  ESCAPE: 27,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  BACKSPACE: 8,
};

export default KEYS;

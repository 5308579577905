// @flow

export type InterpolateOptions = {|
  fullUrl: string,
|};

export const INTERPOLATABLE_FIELDS = {
  CURRENT_URL: 'currentUrl',
};

export const interpolateContent = (
  content: ?string,
  { fullUrl }: InterpolateOptions
): string => {
  if (!content) return '';

  return content
    .toString()
    .replace(`{{${INTERPOLATABLE_FIELDS.CURRENT_URL}}}`, fullUrl);
};

// @flow
import React from 'react';

import type { SiteContext as SiteContextType, Page } from '@/types/site';
import type { CookieContext } from '@/types/cookies';
import type { User, Namespace } from '@/types/models';
import type { CartContext as CartContextType } from '@/types/stores';
import type {
  PaywallContext as PaywallContextType,
  ImageContext as ImageContextType,
} from '@/types/site';

export const SiteContext = (React.createContext(
  null
): React$Context<?SiteContextType>);
export const PageContext = (React.createContext(null): React$Context<?Page>);
export const UserContext = (React.createContext(null): React$Context<?User>);
export const CartContext = (React.createContext(
  null
): React$Context<?CartContextType>);
export const CookiesContext = (React.createContext({
  cookies: {},
  setCookie: () => undefined,
  destroyCookie: () => undefined,
}): React$Context<CookieContext>);
export const PaywallContext = (React.createContext({
  notifiyView: () => {},
}): React$Context<?PaywallContextType>);

export const ContentContext = (React.createContext(null): React$Context<?any>);

export const UserAgentContext = (React.createContext(
  ''
): React$Context<?string>);

export const LayoutSizeDivisionContext = (React.createContext(
  1
): React$Context<number>);

export const NamespacesContext = (React.createContext([]): React$Context<
  Array<Namespace>
>);

export const VersionContext = (React.createContext(1): React$Context<number>);
export const LayoutLocationContext = (React.createContext({
  uuid: '',
  path: '',
  source: '',
}): React$Context<{
  uuid: string,
  path: string,
  source: '',
}>);

export const ImageContext = (React.createContext({
  loadImagesSync: false,
}): React$Context<ImageContextType>);

// $FlowIgnore
export const APIContext = React.createContext({
  getImageUrl: () => '',
  siteId: '',
});

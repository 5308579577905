// @flow
import LocalStore from 'store';
import { pick } from 'lodash';

import type { CartStoreState } from '@/types/stores';

import { LOCAL_STORAGE_PERSISTED_DATA, DEFAULT_STATE } from '../constants';

export const emptyLocalStorage = () => {
  LocalStore.enabled && LocalStore.set('cartState', DEFAULT_STATE);
};

export const updateLocalStorage = (cartState: CartStoreState) => {
  if (!LocalStore.enabled) return;

  const persistedState = pick(cartState, LOCAL_STORAGE_PERSISTED_DATA);

  LocalStore.set('cartState', persistedState);
};

export const getStateFromLocalStorage = (): $Shape<CartStoreState> =>
  LocalStore.enabled && LocalStore.get('cartState')
    ? (LocalStore.get('cartState'): CartStoreState)
    : {};

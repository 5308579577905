import Reflux from 'reflux';

// Not sure sync:false is really useful
const CartStoreActions = (Reflux.createActions({
  empty: { sync: true },
  setUserPrice: { sync: false },
  setAddress: { sync: true },
  setGift: { sync: true },
  addItem: { sync: true },
  initializeCheckout: { sync: true },
  goToNextStep: { sync: false },
  goToPreviousStep: { sync: true },
  setError: { sync: true },
  setIsGift: { sync: true },
  removeItem: { sync: true },
  removeItems: { sync: true },
  addJustificativeToItem: { sync: true },
  updateQuantity: { sync: true },
  setDonation: { sync: true },
  updatePaymentIntent: { sync: true },
  forceSetState: { sync: true },
  addItemOptions: { sync: true },
}): CartStoreActionsType);

export default CartStoreActions;

// @flow
import * as React from 'react';
import { merge, keyBy, pick } from 'lodash';

import type {
  LinksContext as LinksContextType,
  MagellanOptions,
  SiteContext,
} from '@/types/site';

import invariant from '@/helpers/invariant';
import { getFeatureFlags, featureEnabled } from '@/helpers/models/instance';

const getMagellanLinks = (
  options: MagellanOptions,
  fullUrl: string
): $Shape<LinksContextType> => {
  const url = typeof window !== 'undefined' ? window.location.href : fullUrl;

  const links: LinksContextType = {
    subscribe: {
      internal: false,
      url: options.subscribeUrl,
      isPageId: false,
      query: {
        url,
      },
    },
    gift: {
      internal: false,
      url: options.subscribeUrl,
      isPageId: false,
      query: {
        url,
      },
    },
    redeemCode: {
      internal: false,
      url: options.subscribeUrl,
      isPageId: false,
      query: {
        url,
      },
    },
    cart: {
      internal: false,
      url: options.subscribeUrl,
      isPageId: false,
      query: {
        url,
      },
    },
    login: {
      login: {
        internal: true,
        url: '/login',
        isPageId: false,
      },
      forgotPass: {
        internal: false,
        url: options.forgotPassUrl,
        isPageId: false,
        query: {
          url,
        },
      },
      register: {
        internal: false,
        url: options.subscribeUrl,
        isPageId: false,
        query: {
          url,
        },
      },
    },
    myAccount: {
      profile: {
        internal: false,
        url: options.myAccountUrl,
        isPageId: false,
        query: {
          url,
        },
      },
      subscriptions: {
        internal: false,
        url: options.myAccountUrl,
        isPageId: false,
        query: {
          url,
        },
      },
      paymentMeans: {
        internal: false,
        url: options.myAccountUrl,
        isPageId: false,
        query: {
          url,
        },
      },
      purchases: {
        internal: false,
        url: options.myAccountUrl,
        isPageId: false,
        query: {
          url,
        },
      },
    },
  };

  return links;
};

export const getLinks = (
  siteContext: SiteContext,
  fullUrl: string
): LinksContextType => {
  invariant(!!siteContext, 'Site context should be defined');

  const { site, siteConfig } = siteContext;
  const { siteMap } = site;
  const { pages } = siteMap;
  const { magellan: magellanOptions, options } = siteConfig;
  const featureFlags = getFeatureFlags(site);
  const magellanEnabled = featureEnabled('magellan', featureFlags);
  const dynamicPages = keyBy(
    pages.map((page) => pick(page, ['_id', 'path'])),
    '_id'
  );

  const links: LinksContextType = {
    subscribe: {
      internal: true,
      url: options.subscribePageId,
      isPageId: true,
    },
    gift: {
      internal: true,
      url: '/gift',
      isPageId: false,
    },
    redeemCode: {
      internal: true,
      url: '/redeem-code',
      isPageId: false,
    },
    cart: {
      internal: true,
      url: options.cartPageId,
      isPageId: true,
    },
    login: {
      login: {
        internal: true,
        url: '/login',
        isPageId: false,
      },
      forgotPass: {
        internal: true,
        url: '/forgotPass',
        isPageId: false,
      },
      register: {
        internal: true,
        url: options.subscribePageId || '/register',
        isPageId: !!options.subscribePageId,
      },
    },
    myAccount: {
      profile: {
        url: '/my-account/profile',
        internal: true,
        isPageId: false,
      },
      subscriptions: {
        internal: true,
        url: '/my-account/subscriptions',
        isPageId: false,
      },
      paymentMeans: {
        internal: true,
        url: '/my-account/paymentMeans',
        isPageId: false,
      },
      purchases: {
        internal: true,
        url: '/my-account/purchases',
        isPageId: false,
      },
    },
    dynamicPages,
  };

  if (magellanEnabled && !!magellanOptions)
    return merge(links, getMagellanLinks(magellanOptions, fullUrl));

  return links;
};

const LinksContext = (React.createContext(
  null
): React$Context<?LinksContextType>);

export default LinksContext;

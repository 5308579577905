import { Promise } from 'es6-promise';
import _ from 'lodash';
import request from 'superagent';
import config, { getApiUrl } from './config';
import { buildQueryString } from './misc';
import { injectEnvironment } from '@/helpers/request';

/**
 * Non-static version for server (to fix global instance bug)
 */
class API2 {
  constructor(
    { baseUrl, headers, withCredentials, withEnvironmentInfo } = {
      baseUrl: '',
      headers: {},
      withCredentials: true,
      withEnvironmentInfo: true,
    }
  ) {
    this.baseUrl = baseUrl || getApiUrl();
    this.headers = headers || {};
    this.cookies = null;
    this.withCredentials = withCredentials;
    this.requestMap = {};
    this.withEnvironmentInfo = withEnvironmentInfo;
  }

  setServerMode(cookiesToForward) {
    if (config.SERVER_API_URL) this.baseUrl = config.SERVER_API_URL;
    if (cookiesToForward) this.cookies = cookiesToForward;
    // TODO disable gzip
  }

  setHeader(name, value) {
    this.headers = {
      ...(this.headers || {}),
      [name]: value,
    };
  }

  isPUFApi() {
    return [getApiUrl(), config.SERVER_API_URL].includes(this.baseUrl);
  }

  /**
   * Make an HTTP request to the API
   *
   * @param method    HTTP Method, e.g. "get", "post" etc.
   * @param path      Resource path, e.g. "/Content/ct0165"
   * @param params    Query parameters as an object, e.g. { limit: 10, answered: false }
   * @param body      Payload
   * @param channel   Name of a channel. Will auto-abort any pending request of same channel
   * @returns {Promise<any>}
   * @private
   */
  _request(method, path, baseParams = null, body = null, channel) {
    const mustAppendBaseSlash = !path.startsWith('/');
    const params = this.withEnvironmentInfo
      ? injectEnvironment(baseParams)
      : baseParams;

    let url = `${this.baseUrl}${mustAppendBaseSlash ? '/' : ''}${path}${
      params ? '?' + buildQueryString(params) : ''
    }`;

    // Note: if error = UNABLE_TO_VERIFY_LEAF_SIGNATURE this is probably because the cert full chain is missing
    // try this on the server:
    // https://git.coolaj86.com/coolaj86/ssl-root-cas.js#important-try-this-first
    // otherwise *temporarily* you can use: process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = '0';

    return new Promise((resolve, reject) => {
      if (channel) this.abort(channel);

      console.debug('API2> --', method, url);

      let chain = request[method](url, body);

      if (this.withCredentials) {
        chain.withCredentials();
      }

      for (const [name, value] of Object.entries(this.headers)) {
        chain.set(name, value);
      }

      if (this.cookies) chain.set('cookie', this.cookies);

      if (!this.isPUFApi()) {
        return chain.then((response) => resolve(response.body)).catch(reject);
      }

      chain
        .then(
          (response) => {
            if (this.cookies && response.headers['set-cookie']) {
              response.headers['set-cookie'].forEach((setCookieHeader) => {
                const cookieValue = setCookieHeader.split(';')[0];
                if (!this.cookies.includes(cookieValue)) {
                  console.debug('API> server-mode: add cookie', cookieValue);
                  this.cookies += '; ' + cookieValue;
                }
              });
            }

            if (channel) delete this.requestMap[channel];
            if (!response.body || !('result' in response.body)) {
              reject(response.body && response.body.error);
            } else {
              let b = response.body;
              if (_.isObject(b.result)) {
                if (_.isInteger(b.count)) b.result.count = b.count; // TODO _count, _offset... instead
                if (params && params.offset) b.result.offset = params.offset;
                if (params && params.limit) b.result.limit = params.limit;
              }

              resolve(b.result);
            }
          },
          (err) => {
            if (err && err.code === 'ABORTED') {
              console.debug('API2> Aborted', url);
              // TODO if aborted by new request, return new promise
              reject({
                code: 'ABORTED',
                message: 'Opération annulée (API2)',
              });
            } else {
              console.warn(
                'API2> Error',
                err && err.code,
                (err && err.message) || err.status || err,
                '--',
                url
              );
              const error = _.get(
                err,
                'response.body.error',
                _.get(err, 'response.body', {})
              ); // TODO check if we have the 2 cases
              reject({
                code: error.code || err.status,
                message: error.message || err.toString(),
              });
            }
          }
        )
        .catch((e) =>
          console.error('Exception in API response handling', method, url, e)
        ); // useless?

      if (channel && chain) this.requestMap[channel] = chain; //(chain.xhr || chain.req);
    });
  }

  abort(channel) {
    if (this.requestMap[channel]) {
      console.debug('API2> abort ', channel);
      this.requestMap[channel].abort();
      delete this.requestMap[channel];
      return true;
    }
    return false;
  }

  /**
   * Make a GET request to the API
   *
   * @param path      Resource path, e.g. "/Content/ct0165"
   * @param params    Query parameters as an object, e.g. { limit: 10, answered: false }
   * @param channel   Name of a channel. Will auto-abort any pending request of same channel
   * @returns {Promise.<any>}
   */
  get(path, params, channel) {
    return this._request('get', path, params, null, channel);
  }

  /**
   * Make a POST request to the API
   *
   * @param path      Resource path, e.g. "/Content/ct0165"
   * @param body      Payload
   * @param channel   Name of a channel. Will auto-abort any pending request of same channel
   * @returns {Promise.<any>}
   */
  post(path, body, channel) {
    return this._request('post', path, null, body, channel);
  }

  /**
   * Make a PATCH request to the API (not used)
   *
   * @param path      Resource path, e.g. "/Content/ct0165"
   * @param body      Payload
   * @param channel   Name of a channel. Will auto-abort any pending request of same channel
   * @returns {Promise.<any>}
   */
  patch(path, body, channel) {
    return this._request('patch', path, null, body, channel);
  }

  /**
   * Make a PUT request to the API
   *
   * @param path      Resource path, e.g. "/Content/ct0165"
   * @param body      Payload
   * @param channel   Name of a channel. Will auto-abort any pending request of same channel
   * @returns {Promise.<any>}
   */
  put(path, body, channel) {
    return this._request('put', path, null, body, channel);
  }

  /**
   * Make a DELETE request to the API
   *
   * @param path      Resource path, e.g. "/Content/ct0165"
   * @param params    Query parameters as an object, e.g. { limit: 10, answered: false }
   * @param channel   Name of a channel. Will auto-abort any pending request of same channel
   * @returns {Promise.<any>}
   */
  del(path, params, channel) {
    return this._request('del', path, params, null, channel);
  }
}

export default API2;
export const backendApi = new API2({});

import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSetRecoilState } from 'recoil';

import { notificationCenterState } from '@/recoil/atoms';

export default function usePushNotification() {
  const setNotificationCenter = useSetRecoilState(notificationCenterState);

  const pushNotification = React.useCallback(
    ({ type, text, time }) => {
      setNotificationCenter((previousNotifications) => [
        ...previousNotifications,
        {
          id: uuidv4(),
          type: type,
          text: text,
          time: 3000 || time,
        },
      ]);
    },
    [setNotificationCenter]
  );

  return pushNotification;
}

// @flow
import { useContext } from 'react';

import type { UserStoreProps } from '@/types/stores';
import type {
  User,
  FeatureFlags,
  VisitorSession,
  SubscriptionFormula,
} from '@/types/models';
import type { SiteConfig, Site, LinksContext } from '@/types/site';

import useStore from './useStore';
import useLinks from './useLinks';
import { SiteContext, UserAgentContext } from '@/globals/contexts';
import { getFeatureFlags } from '@/helpers/models/instance';
import { useUserAgent } from 'next-useragent';
import usePage from './usePage';

type Returned = {|
  user: ?User,
  siteId: string,
  siteConfig: SiteConfig,
  featureFlags: FeatureFlags,
  site: Site,
  session: VisitorSession,
  formulas: Array<SubscriptionFormula>,
  links: LinksContext,
  useragent: any,
  ua: any,
  page: any,
|};

export default function useGlobal(): Returned {
  const siteContext = useContext(SiteContext);
  const { user, session } = useStore<UserStoreProps>('UserStore');
  const useragent = useContext(UserAgentContext);
  const page = usePage();
  const ua = useUserAgent(useragent);

  if (!siteContext) throw new Error('Missing site context');
  const { site, instanceId, siteConfig, formulas } = siteContext;

  const featureFlags = getFeatureFlags(site);

  const links = useLinks();

  return {
    siteId: instanceId,
    featureFlags,
    user,
    siteConfig: siteConfig,
    site: site,
    session,
    formulas,
    links,
    useragent,
    ua,
    page,
  };
}

// @flow
import { useContext } from 'react';

import type { SiteConfigOptions } from '@/types/site';

import { SiteContext } from '@/globals/contexts';

export default function useSiteOptions(): SiteConfigOptions {
  const context = useContext(SiteContext);

  if (!context) throw new Error('Missing site context');

  return context.siteConfig.options;
}
